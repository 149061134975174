<template>
  <div class="flex flex-col text-left">
    <div class="flex flex-col">
      <div class="w-full flex space-x-2">
        <div class="w-full flex flex-col">
          <label for="">Kompetenz</label>
          <select
            v-model="skill.personalityskilldata"
            class="mb-2 border rounded px-2 py-1 text-xs focus:outline-none bg-transparent"
            :class="{
              'border-red-500': $v.skill.personalityskilldata.$error,
            }"
          >
            <option value="">Kompetenz wählen</option>
            <option v-for="(skill, index) in skills" :key="`option-${index}`" :value="skill.id">
              {{ skill.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="w-full flex space-x-2">
        <div v-show="skillDescription" class="w-full flex flex-col">
          <label for="">Beschreibung</label>
          <p
            class="mb-2 border rounded px-2 py-1 text-xs appearance-none focus:outline-none bg-transparent"
          >
            {{ skillDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { getAllData, createOne, updateOne } from '@/services/skill'

const tempSkill = {
  roleprofile: '',
  personalityskilldata: '',
}

export default {
  name: 'new-skill',
  props: ['payload', 'parent_id'],
  components: {},
  data() {
    return {
      skill: { ...tempSkill },
      skills: [],
    }
  },
  validations() {
    return {
      skill: {
        personalityskilldata: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.skill = this.payload
        ? {
            ...this.payload,
            id: this.payload.id,
            personalityskilldata: this.payload.personalityskilldata.id,
          }
        : { ...tempSkill }

      getAllData().then(response => {
        this.skills = response.data.results
      })
    },
    save() {
      this.$v.skill.$touch()

      if (this.$v.$invalid) {
        this.$toast.warning('Bitte füllen Sie die erforderlichen Felder aus')
      } else {
        this.skill['roleprofile'] = this.parent_id
        if (this.skill.id) {
          updateOne(this.skill).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        } else {
          createOne(this.skill).then(() => {
            this.$emit('close-modal', { show: false })
            // this.$toast.success(response.data.message)
          })
        }
      }
    },
  },
  computed: {
    skillDescription() {
      const skill = this.skills.filter(skill => skill.id === this.skill.personalityskilldata)

      return skill.length ? _.first(skill).description : null
    },
  },
  watch: {},
}
</script>
